import Image from 'next/image';
import React from 'react';

function MobileAppSection() {
  return (
    <>
      <section className="mobile-app-section">
        <div className="container ">
          <div className="row pt-md-5 pt-4  ">
            <div className="col-md-6 pb-3 d-flex align-items-center text-md-start text-center">
              <div className="">
                <h3 className="fs-50 font-sfpro fw-600">Book anything from your phone</h3>
                <p className="fs-20 label-color-3 font-sfpro fw-500 ">
                  You can easily and quickly book a vehicle with ur Apporio All-in-One App
                  <br /> from Playstore. Just Select your branch and vehicle and complete your <br /> bookings.
                </p>
                <div className="text-md-start text-center">
                  <img src="/images/playstore.jpg" className="play-img" alt="" width={140} height={40} />
                </div>
              </div>
            </div>
            <div className="col-6 d-none d-md-block text-end">
              <img src="/images/mobile-app.png" className="img-fluid" alt="" width={550} height={530} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default MobileAppSection;
